<template>
	<div>
		<div v-if="cashierState.transferFundsIn">
			<div class="input-section" v-if="buyInOfferSelected">
				<label for="fromPlayerCashCOC">{{ languageStrings.cashReceived }}</label>
				<span>
					<input
						hidden
						type="text"
						name="fromPlayerCashCOC"
						@click="numInputPadInit('fromPlayerCashCOC')"
						v-model="regularAU"
						placeholder="From Player Cash COC"
						autocomplete="off"
						onfocus="blur()"
					/>
					<input
						type="text"
						name="displayCOC"
						@click="numInputPadInit('fromPlayerCashCOC')"
						v-model="displayCOC"
						placeholder="From Player Cash"
						autocomplete="off"
						onfocus="blur();"
					/>
				</span>
				<button class="btn buy-in-btn" :id="`commit-offer${buyInOffer.id}`" @click="commitTransaction()">{{ languageStrings.commitTransaction }}</button>
			</div>
		</div>
		<button
			:id="`reprint-button-${buyInOffer.id}`"
			v-if="cashierState.portExists && buyInTransactionDetails.id"
			class="btn buy-in-btn"
			@click="printTransactionReceipt()"
		>
			{{ languageStrings.reprintLast }} <strong class="strong">{{ buyInOffer.name }}</strong> {{ languageStrings.receipt }}
		</button>
		<transition name="fade">
			<NumInputPad
				v-if="inputConfig.inputField"
				:inputConfig="inputConfig"
				:currencyInfo="cashierState.currencyInfo"
				:inGameCurrencyTool="inGameCurrencyTool"
				:systemCurrencyTool="systemCurrencyTool"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
			/>
		</transition>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "CommitBuyInOffer",
	props: {
		buyInOfferSelected: Boolean,
		cashierState: Object,
		buyInOffer: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		NumInputPad,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			fromPlayerCashCOC: 0,
			displayCOC: this.systemCurrencyTool.formatFromAU(0, this.systemMinorOrFull),
			regularAU: 0,
			buyIn: {
				cashierBankId: 0,
				playerUserId: "",
				accountAccessCode: 0,
				buyInOfferId: 0,
				fromPlayerCashCOC: 0,
			},
			transactionReport: {},
			buyInTransactionDetails: {},
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	watch: {
		buyInOfferSelected() {
			if (!this.buyInOfferSelected) this.fromPlayerCashCOC = 0;
		},
		regularAU() {
			this.displayCOC = this.systemCurrencyTool.formatFromAU(Number(this.regularAU), this.systemMinorOrFull);
		},
	},
	created() {
		this.eventBus.on("closeKeypad", () => {
			this.inputConfig.inputField = null;
			this.displayCOC = this.systemCurrencyTool.formatFromAU(Number(this.regularAU), this.systemFull);
		});
		onBeforeUnmount(() => {
			this.inputConfig.inputField = null;
			this.eventBus.off("closeKeypad");
		});
	},
	mounted() {},
	methods: {
		async printTransactionReceipt() {
			let playerDisplayName = this.buyInTransactionDetails.playerDisplayName;
			let regAU = this.inGameCurrencyTool.formatFromAU(this.buyInTransactionDetails.playerAccount.account.balance.regularAU, this.systemFull);
			let proAU = this.inGameCurrencyTool.formatFromAU(this.buyInTransactionDetails.playerAccount.account.balance.promoAU, this.systemFull);
			let transactRegAU = this.buyInTransactionDetails.transactionsAu.regularAU;
			let transactPromoAU = this.buyInTransactionDetails.transactionsAu.promoAU;
			let transferReg = this.systemCurrencyTool.formatFromAU(transactRegAU, this.systemFull);
			let transferPro = this.systemCurrencyTool.formatFromAU(transactPromoAU, this.systemFull);

			// First element of printLines array; Initialize printer, set the code page to 16 (Windows-1252),
			// and set justify center for the header section.  The Windows-1252 encoding gives us access to
			// several currency symbols.  For this to work, we need to use the win1252Encoder later when
			// converting all this to bytes for the serial port.
			// https://en.wikipedia.org/wiki/Windows-1252
			let printLines = [`${this.textCodes.ESC}@${this.textCodes.ESC}t\x10${this.textCodes.ESC}a1`];

			// Header and site branding section
			printLines.push(this.textCodes.STARDIVIDER);
			printLines.push(this.textCodes.LF);
			printLines.push(this.setFontSize(this.cashierState.casinoName, "\x11"));
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.STARDIVIDER);

			// Transaction section
			printLines.push(this.textCodes.LF);
			printLines.push(this.setFontSize("BUY-IN", "\x20"));
			printLines.push(new Date().toLocaleTimeString([], this.dateOptions));
			printLines.push(`${this.textCodes.ESC}a0`);
			printLines.push(this.textCodes.LF);
			printLines.push(this.formatSpacedLine("Player:", playerDisplayName));
			printLines.push(`${this.textCodes.LF}${this.textCodes.ESC}a1`);
			printLines.push(`Buy-in to player account`);
			printLines.push(`${this.buyInTransactionDetails.name}${this.textCodes.ESC}a0`);
			printLines.push(this.formatSpacedLine("Regular:", transferReg));
			printLines.push(this.formatSpacedLine("Promo:", transferPro));

			// Player balance section
			printLines.push(`${this.textCodes.ESC}a1`);
			printLines.push(this.textCodes.LINEDIVIDER);
			printLines.push(`Resulting Account Balance${this.textCodes.ESC}a0`);
			printLines.push(this.formatSpacedLine("Regular credits:", regAU));
			printLines.push(this.formatSpacedLine("Promo credits:", proAU));

			// Footer section
			printLines.push(this.textCodes.LINEDIVIDER);
			printLines.push(`${this.textCodes.ESC}a1`);
			printLines.push("Good Luck!");
			printLines.push(`${this.textCodes.ESC}a0`);
			printLines.push(this.textCodes.STARDIVIDER);

			// Feed a couple of lines and cut paper
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.LF);
			printLines.push(`${this.textCodes.GS}VB${66}`);

			let printJob = printLines.join(`${this.textCodes.LF}`);

			this.eventBus.emit("printTicket", printJob);
		},
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.keypadInput = this.regularAU;
			this.inputConfig.locale = false;
		},
		async commitTransaction() {
			this.serverStatus.serverBusy = true;
			this.serverStatus.busyText = "Commiting Buy-in Transaction";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);

			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			this.fromPlayerCashCOC = this.systemCurrencyTool.toCurrencyFromAU(Number(this.regularAU));

			switch (true) {
				case isNaN(this.fromPlayerCashCOC):
					this.status.message = "Transfer amount must have some value.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case !(this.fromPlayerCashCOC >= this.buyInOffer.minimumCOC):
					this.status.message = "Transfer amount must be at least the minimum buy-in.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case this.fromPlayerCashCOC > this.buyInOffer.maximumCOC:
					this.status.message = "Transfer amount can not be greater than maximum buy-in.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				// case this.serverStatus.serverBusy:
				// 	console.log(this.serverStatus.serverBusy);
				// 	return;
			}

			try {
				let body = {
					cashierBankId: this.cashierState?.cashierBankId,
					playerUserId: this.cashierState?.playerAccount?.user?.id,
					accountAccessCode: this.cashierState?.playerAccount?.accountAccessCode,
					buyInOfferId: this.buyInOffer.id,
					fromPlayerCashCOC: this.fromPlayerCashCOC,
				};

				let requestUrl = new URL("/api/v1/cashier/player/funds", this.rabbitsfootHostUrl);
				let headerObj = new Headers();
				headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let request = new Request(requestUrl.toString(), {
					method: "POST",
					body: JSON.stringify(body),
					headers: headerObj,
				});

				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				// 200 codes that are still some sort of fail for the action attempted
				if (fetchStatus.ok) {
					switch (dataJson.status) {
						case "BankNotFound":
							this.status.ok = false;
							this.status.message = `Bank #${this.cashierState.cashierBankId} Not Found`;
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InsufficientBankFunds":
							this.status.ok = false;
							this.status.message = "Insufficient Bank Funds";
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InsufficientPlayerFunds":
							this.status.ok = false;
							this.status.message = "Insufficient Player Funds";
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InvalidAccountAccessCode":
							this.status.ok = false;
							this.status.message = "Invalid Account Access Code";
							this.eventBus.emit("updateStatus", this.status);
							this.eventBus.emit("requestRefreshPlayerCode");
							return false;
						case "InvalidBuyInOffer":
							this.status.ok = false;
							this.status.message = "Invalid Buy In Offer";
							this.eventBus.emit("updateStatus", this.status);
							return false;
					}
				}

				// For new accounts that have never had a balance at this casino
				let newAccountBalance = {
					regularAU: 0,
					promoAU: 0,
				};

				let transactionsAu = this.calculateBuyInTransactionBalances(
					this.cashierState?.playerAccount?.account ? this.cashierState.playerAccount.account.balance : newAccountBalance,
					dataJson?.playerAccount?.balance
				);

				let newCashierState = this.cashierState;

				newCashierState.currentBank.currentBalanceCOC = dataJson.cashierBank.currentBalanceCOC;
				newCashierState.playerAccount.account = dataJson.playerAccount;
				let buyInCreditRegularAU = body.fromPlayerCashCOC * this.buyInOffer.creditRegularAUPerCashCOC;
				let buyInCreditPromoAU = body.fromPlayerCashCOC * this.buyInOffer.creditPromoAUPerCashCOC;

				this.buyInTransactionDetails = {
					id: this.buyInOffer.id,
					name: this.buyInOffer.name,
					creditRegularCOC: this.systemCurrencyTool.toCurrencyFromAU(buyInCreditRegularAU),
					creditPromoCOC: this.systemCurrencyTool.toCurrencyFromAU(buyInCreditPromoAU),
					playerDisplayName: this.cashierState.playerAccount.user.displayName,
					playerAccount: newCashierState.playerAccount,
					transactionsAu: transactionsAu,
				};

				this.regularAU = 0;

				this.eventBus.emit("updateCashierState", newCashierState);
				this.printTransactionReceipt();
				this.status.message = `Cash: ${this.systemCurrencyTool.formatFromAU(
					transactionsAu.regularAU,
					this.systemMinorOrFull
				) } Promo: ${this.systemCurrencyTool.formatFromAU(transactionsAu.promoAU, this.systemMinorOrFull)} Successfully Transfered.`;
				this.status.ok = true;
				this.eventBus.emit("closePlayerSession");
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("deselectBuyInOffer", null);

				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverStatus.serverBusy = false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-section {
	width: 100%;
	align-items: center;
}

.input-section span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.input-section input {
	max-width: 90%;
}

.buy-in-btn {
	color: black;
	background-color: azure;
	margin: 5px auto;
}
</style>
